<template>
  <div class="wrapper">
    <div id="buttonFrame" ref="buttonFrame" :style="heightAndWidth"></div>
    <div id="buttonSlot" ref="buttonSlot" :class="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["active", "borderColor"],
  data() {
    return {
      height: null,
      width: null,
    };
  },
  computed: {
    isActive() {
      return this.active ? "active" : "";
    },
    heightAndWidth() {
      let result = {
        height: `${this.height + 4}px`,
        width: `${this.width + 4}px`,
      };
      return result;
    },
  },
  mounted() {
    this.height = this.$refs.buttonSlot.clientHeight;
    this.width = this.$refs.buttonSlot.clientWidth;
    if (this.borderColor) {
      this.$refs.buttonFrame.style.borderColor = this.borderColor;
    }
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  width: auto;
  padding: 0px;
}

#buttonFrame {
  position: absolute;
  border: 3px dotted;
  border-radius: 0.5rem;
  color: black;
  padding: 2px 5px;
  border-color: black;
  z-index: 3;
}

#buttonSlot {
  position: relative;
  text-align: center;
  padding: 6px;
  border-radius: 0.5rem;
  top: 6px;
  left: 6px;
  z-index: 2;
}

#buttonSlot.active {
  background-color: var(--main-color-red);
  color: white;
}
</style>
