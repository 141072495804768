<template>
  <base-bg>
    <div>
      <top-nav-bar></top-nav-bar>
      <div class="page with-footer">
        <section class="container my-2">
          <router-link
            :to="{ name: $routeNames.routeRecipesList }"
            class="
              link
              d-block
              text-center text-red text-uppercase
              my-3
              fw-bold
            "
          >
            <span class="subtitle">{{ seasonName }}-Rezepte</span>
          </router-link>
          <div
            class="my-2"
            v-if="getRecipe"
            @click="onClick($routeNames.routeRecipePage, getRecipe.id)"
          >
            <div
              alt=""
              class="main-picture my-3"
              :aria-label="getRecipe.title"
              :style="{ backgroundImage: `url('${recipeUrl}')` }"
            />
            <h3 class="text-center text-handwriting my-3">
              {{ getRecipe.title }}
            </h3>
          </div>
          <send-recipe-button></send-recipe-button>
        </section>
        <section class="container my-2">
          <div
            class="row justify-content-between my-4"
            v-if="getIngredient && getShop"
          >
            <div class="col-6">
              <router-link
                :to="{ name: $routeNames.routeIngredientsList }"
                class="
                  d-block
                  text-red text-center
                  fw-bold
                  fs-6
                  custom-link
                  my-3
                "
              >
                <span class="subtitle">SAISONKALENDER</span></router-link
              >
              <div
                @click="
                  onClick($routeNames.routeIngredientPage, getIngredient.id)
                "
              >
                <div
                  class="item-image"
                  :style="{ backgroundImage: `url('${getIngredient.path}')` }"
                ></div>

                <!-- <img
              :src="getIngredient.path"
              class="main-picture my-2"
              alt=""
              :aria-label="getIngredient.title"
            /> -->
                <h3 class="text-handwriting text-center mt-3">
                  {{ getIngredient.title }}
                </h3>
              </div>
            </div>
            <div class="col-6">
              <router-link
                :to="{ name: $routeNames.routeMapPage }"
                class="
                  d-block
                  text-red text-center
                  fw-bold
                  fs-6
                  custom-link
                  my-3
                "
              >
                <span class="subtitle">AUS DER REGION</span></router-link
              >
              <div @click="onClick($routeNames.routeMapItemPage, getShop.id)">
                <div
                  class="item-image"
                  :style="{ backgroundImage: `url('${getShop.path}')` }"
                ></div>
                <!-- <img
              :src="getShop.path"
              class="main-picture my-2"
              alt=""
              :aria-label="getShop.title"
            /> -->
                <h3 class="text-handwriting text-center mt-3">
                  {{ getShop.title }}
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="container mt-2">
          <div class="mt-4" v-if="getInformation">
            <router-link
              :to="{ name: $routeNames.routeInformationsList }"
              class="d-block text-red text-center fw-bold fs-6 custom-link my-3"
              ><span class="subtitle">KLIMA-TIPPS</span></router-link
            >
            <div
              @click="
                onClick($routeNames.routeInformationPage, getInformation.id)
              "
            >
              <div
                alt=""
                class="main-picture my-3"
                :aria-label="getRecipe.title"
                :style="{ backgroundImage: `url('${informationUrl}')` }"
              />
              <h3 class="text-handwriting text-center mt-3">
                {{ getInformation.title }}
              </h3>
            </div>
          </div>
        </section>
      </div>
    </div>
  </base-bg>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import converter from "../../assets/helpers/converter.js";
import SendRecipeButton from "./SendRecipeButton.vue";
// import BaseArrowRight from "../../components/UI/BaseArrowRight.vue"

export default {
  components: {
    SendRecipeButton,
    //  BaseArrowRight
  },
  inject: ["activePage"],
  data() {
    return {
      recipeUrl: "",
      informationUrl: "",
    };
  },
  computed: {
    ...mapGetters([
      "getRandomSeasonRecipe",
      "getRecipe",
      "getRandomSeasonIngredient",
      "getRandomSeasonInformation",
      "getIngredient",
      "getInformation",
      "getRandomShop",
      "getShop",
    ]),
    seasonName() {
      let seasonName = converter.getSeasonName();
      if (seasonName == "Frühling") {
        seasonName = "Frühlings";
      }
      return seasonName;
    },
  },
  methods: {
    ...mapActions([
      "loadRecipe",
      "loadIngredient",
      "loadInformation",
      "loadShop",
    ]),
    onClick(page, id) {
      this.$router.push({ name: page, params: { id } });
    },
    setImage(url) {
      this.image = "";
      let that = this;
      let newImage = new Image();
      console.log(url);
      newImage.onload = () => {
        that.image = url;
      };
      newImage.src = url;
    },
  },
  async created() {
    await this.loadRecipe(this.getRandomSeasonRecipe);
    await this.loadIngredient(this.getRandomSeasonIngredient);
    await this.loadInformation(this.getRandomSeasonInformation);
    await this.loadShop(this.getRandomShop);

    this.activePage.value = this.$routeNames.routeMainPage;

    this.recipeUrl = await this.getRecipe.path;
    this.setImage(this.recipeUrl);
    this.informationUrl = await this.getInformation.path;
    this.setImage(this.informationUrl);
  },
  mounted() {
    setTimeout(() => {
      this.$connector.checkRating();
    }, 2000);
  },
};
</script>

<style scoped>
.main-picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}

.link {
  display: inline-block;
}

.link:after {
  height: 12px;
  width: 20px;
  display: inline-block;
  background-image: url("../../assets/images/icons/arrowRight.svg");
  background-size: 15px 15px;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  margin-left: 4px;
}

.item-image {
  height: 100%;
  width: 100%;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
}

span.subtitle {
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

@media only screen and (min-width: 768px) {
  .item-image {
    height: 180px;
  }

  .main-picture {
    height: 350px;
  }
}
</style>
