<template>
  <div class="row justify-content-center">
    <a
      class="col-12 col-md-12 col-lg-6"
      href="https://eu.jotform.com/form/210961754477060"
      target="_blank"
    >
      <main-button :active="true" :borderColor="$globals.COLORS_YELLOW">
        <div
          id="recipeTag"
          class="justify-content-center d-flex align-items-center"
        >
          <span class="p-0 text-white fst-italic text-libre text-center"
            >Schick uns Dein Rezept<br />für Mooi</span
          >
        </div>
      </main-button>
    </a>
  </div>
</template>

<script>
import MainButton from "./MainButton.vue";
export default {
  components: { MainButton },
};
</script>

<style scoped>
@media only screen and (min-width: 1025px) {
  #recipeTag {
    height: 80px;
  }
}
</style>

